/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import useIsMountedRef from 'use-is-mounted-ref';
import { filter } from "lodash";
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
// material
import {
    Stack,
    Button,
    Typography,
    Card,
    Tabs,
    Tab,
    Alert,
    Snackbar
} from '@mui/material';
import Iconify from '../../../../components/Iconify';
import { a11yProps, getStorageItem, postData } from '../../../../utils/generalFunctions';
import Loans from '../@tables/Loans';
import TabPanel from '../TabPanel';
import { GET_ALL_LOANS, UPLOAD_LOANS } from '../../../../utils/urls';
import DormantLoansView from '../@tables/DormantLoansView';




export default function TableBanking() {
    const data = useSelector(state => state.selectedGroup)
    const [loans, setLoans] = React.useState([]);
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const uploadRef = React.createRef()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'info',
        message: ''
    });
    const isMountedRef = useIsMountedRef();

    const getLoans = React.useCallback(async () => {
        try {
            if (isMountedRef.current) {
                postData(`${GET_ALL_LOANS}/${data.group_id}`, {}, 'GET').then((response) => {
                    if (response.status) {
                        setLoans(response.data)
                        dispatch({ type: 'set', loans: response.data })
                    } else {
                        dispatch({ type: 'set', loans: [] })
                    }
                })
            }
        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);

    React.useEffect(() => {
        getLoans()
    }, [getLoans])

    const { severity, horizontal, vertical, message, isOpen } = state;
    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'info' });
    };


    const onChoose = async (e) => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            const accept = [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
            ];

            if (accept.includes(file.type)) {
                // Logs
                setState({ ...state, isOpen: true, severity: 'info', message: 'Uploading.... We will notify you once we are done.' });

                const formdata = new FormData();
                formdata.append("group_id", data.group_id);
                formdata.append("loan_excel", file, 'loans.xlsx');

                const token = getStorageItem('auth_token')
                fetch(UPLOAD_LOANS, {
                    method: 'post',
                    // mode: 'no-cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        // 'Content-Type': 'application/json',
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${token}`
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: formdata
                }).then((response) => {
                    setState({ ...state, isOpen: true, severity: 'success', message: 'Loans uploaded successfully.' });
                });

            } else {
                setState({ ...state, isOpen: true, severity: 'error', message: 'Invalid file type' });
            }
        }
    }

    return (
        <Card>
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={5000000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} >
                    {message}
                </Alert>
            </Snackbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mr={3} ml={3} mt={3} mb={3}>
                <Typography variant="body2" gutterBottom>Group Loans</Typography>
                <Stack spacing={1} direction="row">
                    <Stack direction='row' spacing={1} sx={{ display: value !== 0 ? 'none' : '' }}>
                        <Button
                            color='secondary'
                            variant="contained"
                            href='https://centron.co.ke/LoansTemplate.xlsx'
                            target='_blank'
                            startIcon={<Iconify icon="eva:cloud-download-fill" />}
                        >
                            Download Template
                        </Button>
                        <input
                            type="file"
                            hidden
                            ref={uploadRef}
                            name="files[]"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => onChoose(e)}
                        />
                        <Button
                            color='success'
                            sx={{ color: '#fff' }}
                            variant="contained"
                            onClick={() => uploadRef.current.click()}
                            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
                        >
                            Upload Loans
                        </Button>
                    </Stack>
                    <Button
                        variant="text"
                        onClick={getLoans}
                        startIcon={<Iconify icon="eva:refresh-fill" />}
                    >
                        refresh
                    </Button>
                </Stack>
            </Stack>

            <Tabs selectionFollowsFocus
                variant="scrollable"
                value={value}
                color='info'
                onChange={handleChange}
                aria-label="Payments Management"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab sx={{ alignSelf: 'start' }} label={`Loan Requests (${filter(loans, (_item) => _item.status === 0).length})`} {...a11yProps(0)} />
                <Tab sx={{ alignSelf: 'start' }} label={`Approved Requests (${filter(loans, (_item) => _item.status === 1).length})`} {...a11yProps(1)} />
                <Tab sx={{ alignSelf: 'start' }} label={`Declined Requests (${filter(loans, (_item) => _item.status === 2).length})`} {...a11yProps(2)} />
                <Tab sx={{ alignSelf: 'start' }} label={`Fully Paid Loans (${filter(loans, (_item) => _item.status === 3).length})`} {...a11yProps(3)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <DormantLoansView getLoans={getLoans} setValue={setValue} loans={filter(loans, (_item) => _item.status === 0)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Loans getLoans={getLoans} setValue={setValue} loans={filter(loans, (_item) => _item.status === 1)} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DormantLoansView getLoans={getLoans} setValue={setValue} loans={filter(loans, (_item) => _item.status === 2)} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <DormantLoansView getLoans={getLoans} setValue={setValue} loans={filter(loans, (_item) => _item.status === 3)} />
            </TabPanel>
        </Card>
    );
}
