import { useState } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";

import {
    Table,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { TableListHead, TableListToolbar } from '../../table';
import { fNumber } from '../../../../utils/formatNumber';
import CancelSchedule from '../@payments/CancelSchedule';



const TABLE_HEAD = [
    { id: '', label: 'Actions' },
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'member_name', label: 'Client Name', alignRight: false },
    { id: 'mobile', label: 'Telephone', alignRight: false },
    { id: 'account_number', label: 'Alt. A/C', alignRight: false },
    { id: 'method', label: 'Payment Processor', alignRight: false },
    { id: 'transaction_reference', label: 'Payment Receipt', alignRight: false },
    { id: 'transaction_m_bo', label: 'Payment Payload', alignRight: false },
    { id: 'reference', label: 'Payment Remarks', alignRight: false },
    { id: 'amount', label: 'Disbursement Amount', alignRight: false },
    { id: 'to_receive_on', label: 'Expected Disbursment Time', alignRight: false },
    { id: 'charges', label: 'Charges', alignRight: false },
    { id: 'member_no', label: 'A/C', alignRight: false },
    { id: 'cron_running', label: 'Payment Service Active?', alignRight: false },
    { id: 'createdAt', label: 'Created On', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


ScheduledPayments.propTypes = {
    payments: PropTypes.array,
    getPayments: PropTypes.func
}

ScheduledPayments.defaultProps = {
    payments: []
}

export default function ScheduledPayments({ payments, getPayments }) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const filteredSchedules = applySortFilter(payments, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredSchedules.length === 0;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payments.length) : 0;


    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_payment) => `${_payment.id}`.indexOf(query) !== -1
                || `${_payment.mobile}`.indexOf(query) !== -1
                || `${_payment.member_name.toLowerCase()}`.indexOf(query) !== -1
                || `${_payment.transaction_reference.toLowerCase()}`.indexOf(query) !== -1
                || `${_payment.transaction_m_bo.toLowerCase()}`.indexOf(query) !== -1
                || `${_payment.amount}`.indexOf(query) !== -1
                || `${_payment.member_no !== null && _payment.member_no.toLowerCase()}`.indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleCloseCancel = () => {
        setOpenCancel(false)
        getPayments()
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = payments.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const [openCancel, setOpenCancel] = useState(false)
    const [schedule, setSchedule] = useState(null)

    return (
        <>
            <CancelSchedule setOpen={handleCloseCancel} open={openCancel} schedule={schedule} />

            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={payments.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                        />
                        <TableBody>
                            {filteredSchedules && filteredSchedules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id, transaction_reference, reference, transaction_m_bo, method, member_name, account_number, to_receive_on, mobile, amount, charges, member_no, channel, cron_running, createdAt } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell align="right">
                                                <Button onClick={() => {
                                                    setSchedule(row);
                                                    setOpenCancel(true)
                                                }} variant='text'>Cancel</Button>
                                            </TableCell>
                                            <TableCell align="left">{id}</TableCell>
                                            <TableCell align="left">{member_name}</TableCell>
                                            <TableCell align="left">{mobile}</TableCell>
                                            <TableCell align="left">{account_number}</TableCell>
                                            <TableCell align="left">{method.name}</TableCell>
                                            <TableCell align="left">{transaction_reference}</TableCell>
                                            <TableCell align="left">{transaction_m_bo}</TableCell>
                                            <TableCell align="left">{reference}</TableCell>
                                            <TableCell align="left">{fNumber(amount)}</TableCell>
                                            <TableCell align="left">{to_receive_on}</TableCell>
                                            <TableCell align="left">{charges}</TableCell>
                                            <TableCell align="left">{member_no}</TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={cron_running === 1}
                                                // onChange={(event) => handleClick(event, name)}
                                                />
                                            </TableCell>

                                            <TableCell align="left">{createdAt}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={payments.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}