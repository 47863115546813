import * as React from 'react';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import {
    Card,
    Stack,
    Button,
    Tabs,
    Tab,
    Typography
} from '@mui/material';
import Iconify from '../../../components/Iconify';
import { a11yProps } from '../../../utils/generalFunctions';
import CreateGroupDialog from './GroupCreate'
import GroupsListing from '../management/@tables/GroupsListing';
import TabPanel from '../management/TabPanel';
import Fundraisings from '../management/@tables/Fundraisings';

AppGroups.propTypes = {
    groups: PropTypes.array,
    fundraisings: PropTypes.array,
    default_cron_str: PropTypes.string,
    tap: PropTypes.func,
}
AppGroups.defaultProps = {
    groups: [],
    default_cron_str: ''
}
export default function AppGroups({ fundraisings, groups, default_cron_str, tap }) {
    const [createOpen, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card>
            <CreateGroupDialog refreshSystem={tap} default_cron_str={default_cron_str} open={createOpen} setOpen={setOpen} />

            <Stack direction="row" alignItems="center" justifyContent="space-between" mr={5} ml={5} mt={5} mb={5}>
                <Typography variant="h5" gutterBottom>
                    Managed Groups
                </Typography>
                <Button
                    focusRipple
                    variant="contained"
                    onClick={handleClickOpen}
                    startIcon={<Iconify icon="eva:plus-fill" />}
                >
                    Create
                </Button>
            </Stack>
            <Tabs selectionFollowsFocus
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Groups Management"
                sx={{ borderRight: 1, ml: 4, borderColor: 'divider' }}
            >
                <Tab sx={{ alignSelf: 'start' }} label={`Merry-GO-Rounds (${filter((groups), (_group) => _group.account_type === 2).length})`} {...a11yProps(0)} />
                <Tab sx={{ alignSelf: 'start' }} label={`Table Banking Groups  (${filter((groups), (_group) => _group.account_type === 4).length})`} {...a11yProps(1)} />
                <Tab sx={{ alignSelf: 'start' }} label={`M-Chango Groups  (${fundraisings && fundraisings.length})`} {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <GroupsListing groups={groups && filter((groups), (_group) => _group.account_type === 2)} default_cron_str={default_cron_str} tap={tap} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <GroupsListing groups={groups && filter((groups), (_group) => _group.account_type === 4)} default_cron_str={default_cron_str} tap={tap} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Fundraisings fundraisings={fundraisings} tap={tap} />
            </TabPanel>
        </Card>
    );
}
