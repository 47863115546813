/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */

import * as React from 'react';
import { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/';

import PropTypes from 'prop-types';
// material
import {
    Stack,
    Button,
    Typography,
    Card,
    Tabs,
    Tab,
    Box,
    Tooltip,
    TextField,
    Skeleton
} from '@mui/material';
import useIsMountedRef from 'use-is-mounted-ref';
import Iconify from '../../../../components/Iconify';
import { CONTRIBUTIONS } from '../../../../utils/urls';
import { getToday, postData } from '../../../../utils/generalFunctions';
import ContributionsTable from '../@tables/Contributions';
import { fDate } from '../../../../utils/formatTime';
import TableShimmer from '../../../../components/TableShimmer';



Contributions.propTypes = {
    group: PropTypes.object
}


export default function Contributions({ group }) {
    const isMountedRef = useIsMountedRef()
    const [contributions, setContributions] = useState([])
    const [loading, setLoading] = useState(true)
    const [date, setQuery] = useState(getToday())


    const getContributions = React.useCallback(async (date) => {
        if (isMountedRef.current) {
            setLoading(true)
            await postData(`${CONTRIBUTIONS}/${group.id}?date=${date}`, {}, 'GET').then((response) => {
                setLoading(false)
                try {
                    if (response.status) {
                        setContributions(response.contributions)
                    }
                } catch (error) {
                    console.log(error);
                }
            })
        }
    }, [isMountedRef])

    React.useEffect(() => {
        getContributions(getToday())
    }, [getContributions])


    const handleChange = (newValue) => {
        const contribution_date = new Date(newValue)
        const dd = String(contribution_date.getDate()).padStart(2, '0');
        const mm = String(contribution_date.getMonth() + 1).padStart(2, '0');
        const yyyy = contribution_date.getFullYear();

        const query = `${yyyy}-${mm}-${dd}`
        setQuery(query);
        getContributions(query)
    };
    const dateComponent = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label='Disbursement date'
                value={date}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    )
    return (
        <Card>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mr={3} ml={3} mt={3} mb={3}>
                <Typography variant="body2" gutterBottom>{loading ? <Skeleton /> : 'Group Contributions'}</Typography>
                <Stack spacing={1} direction="row">

                    <Button
                        variant="text"
                        onClick={() => getContributions(date)}
                        startIcon={<Iconify icon="eva:refresh-fill" />}
                    >
                        refresh
                    </Button>

                </Stack>
            </Stack>

            {loading ? <TableShimmer/> :
                <ContributionsTable contributions={contributions && contributions.filter(p => p.position !== 0)} childComponent={dateComponent} />}
        </Card>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}