/* eslint-disable-line no-unused-vars */

import { useDispatch } from 'react-redux';
import { filter } from 'lodash';
import * as React from 'react';
import {
  Box, Grid, Container, Typography,
  Snackbar,
  Alert
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import useIsMountedRef from 'use-is-mounted-ref';
import { STATEMENT_URL,FUNDRAISING } from '../utils/urls';
// components
import Page from '../components/Page';
import {
  AppChamas,
  AppSubscriptions,
  AppTotalTuliza,
  AppCurrentBalance,
  AppGroups
} from '../sections/@dashboard/app';
import { postData } from '../utils/generalFunctions';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [statement, setStatement] = useState({});
  const [groups, setGroups] = useState([])
  const [fundraisings, setFundraisings] = useState([])
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()

  const isMountedRef = useIsMountedRef();

  const getStatement = useCallback(async () => {
    await postData(FUNDRAISING, null, 'GET')
      .then(data => {
        if (data.status) {
          if (isMountedRef.current) {
            setFundraisings(data.list);
          }
        }
      }).catch(error => {
        setMessage(error.message)
        setState({ ...state, open: true, severity: 'error' });
      });

    await postData(STATEMENT_URL, null, 'GET')
      .then(data => {
        if (data.status) {
          if (isMountedRef.current) {
            setStatement(data);
            dispatch({ type: 'set', statement: data })

            createObject(data)
          }
        }
      }).catch(error => {
        setMessage(error.message)
        setState({ ...state, open: true, severity: 'error' });
      });
  }, [isMountedRef]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getStatement();
  }, [getStatement]);

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'left',
    severity: 'error'
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ ...state, open: false });
  };

  const { vertical, horizontal, severity, open } = state;

  const createObject = (data) => {
    let list = []
    list = data.groups.map(({ chama }) => {
      const row = {
        id: chama.id,
        name: chama.name,
        savingsId: chama.savings_id,
        frequency: chama.frequency,
        contribution_date:chama.contribution_date,
        contribution_amount: chama.contribution_amount,
        service_charge_amount: chama.service_charge_amount,
        system_amount: chama.system_amount,
        account_number: chama.account_number,
        start_hour: chama.start_hour,
        charge_fines: chama.charge_fines,
        charge_on_percent: chama.charge_on_percent,
        fine_percent: chama.fine_percent,
        fine_charge: chama.fine_charge,
        has_whatsapp: chama.has_whatsapp,
        group_image_url: chama.group_image_url,
        account_type: chama.account_type,
        account_status: chama.status,
        interest_rate: chama.interest_rate,
        cronJob: chama.cron_expression,
        whatsapp_invite_link: `${chama.whatsapp_invite_link.substr(0, 10)}...`,
        whatsapp_invite_copy: `${chama.whatsapp_invite_link}`,
        active: chama.active,
        status: '',
      }

      return row
    })
    setGroups(list)
  }

  return (
    <Page title="Dashboard | Pamoja Pay">
      <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppCurrentBalance statement={statement && statement} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppChamas groups={statement && statement.groups} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppTotalTuliza schedules={statement.schedules && statement.schedules} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppSubscriptions subscriptions={statement && filter(statement.subscriptions, p => p.position !== 0)} />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppGroups fundraisings={fundraisings} tap={getStatement} default_cron_str={statement && statement.default_cron_str} groups={groups} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
