import { useState } from "react";
import PropTypes from 'prop-types'
import * as React from 'react';
import useIsMountedRef from 'use-is-mounted-ref';
import { filter } from "lodash";
import { useDispatch } from "react-redux";
import {
    Table,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Alert,
    Snackbar,
    TablePagination,
} from '@mui/material';
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { postData } from "../../../../utils/generalFunctions";
import { TableListHead, TableListToolbar } from '../../table';
import { STATEMENT_URL, STOP_SCHEDULE } from "../../../../utils/urls";
import { fCurrency } from "../../../../utils/formatNumber";
import { fDateTime } from "../../../../utils/formatTime";

const TABLE_HEAD = [
    { id: '', label: '', alignRight: false },
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'group_name', label: 'Group name', alignRight: false },
    { id: 'frequency', label: 'Group Frequency', alignRight: false },
    { id: 'contribution_date', label: 'Last Payment On', alignRight: false },
    { id: 'next_contribution_date', label: 'Next Payment On', alignRight: false },
    { id: 'account_number', label: 'A/C No', alignRight: false },
    { id: 'numberOfPayments', label: 'No of Payments', alignRight: false },
    { id: 'balance', label: 'Scheduler balance', alignRight: false },
    { id: 'createdAt', label: 'Created On', alignRight: false },
    { id: 'updatedAt', label: 'Updated On', alignRight: false }
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


export default function TulizaPaymentsTable() {
    const dispatch = useDispatch()
    const [schedules, setSchedules] = React.useState([]);
    const isMountedRef = useIsMountedRef();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const filteredSchedules = applySortFilter(schedules, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredSchedules.length === 0;
    const getStatement = React.useCallback(async () => {
        try {
            if (isMountedRef.current) {
                tulizaTransactions()
            }
        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);

    const tulizaTransactions = async () => {
        await postData(`${STATEMENT_URL}?short=choice`, {}, 'GET').then((response) => {
            if (response.status) {
                const { schedules } = response
                setSchedules(schedules)
                dispatch({ type: 'set', schedules })
            } else {
                dispatch({ type: 'set', schedules: [] })
            }
        })
    }
    React.useEffect(() => {
        getStatement()
    }, [])
    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {

            return filter(array, (_schedules) =>
                `${_schedules.id}`.indexOf(query) !== -1
                || `${_schedules.account_number}`.toLowerCase().indexOf(query) !== -1
                || `${_schedules.position}`.toLowerCase().indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const [message, setMessage] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const handleCancel =async (scheduler_id) => {
        setOpen(true)
        await postData(STOP_SCHEDULE, {
            scheduler_id,
            "is_self_initiated": true
        }).then((response) => {
            tulizaTransactions()
            setOpen(false)
            if (response.status) {

                setMessage(response.message)
                setState({ ...state, isOpen: true, severity: 'success' });
            } else {
                setMessage(response.message)
                setState({ ...state, isOpen: true, severity: 'error' });
            }
        })
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = schedules.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'center',
        horizontal: 'top',
        severity: 'error'
    });

    const { severity, horizontal, vertical, isOpen } = state;

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false });
    };


    return (
        <>
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={20000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                childComponent={<Button
                    variant="text"
                    onClick={() => tulizaTransactions()}
                    startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                    refresh
                </Button>}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={schedules.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                        />
                        <TableBody>
                            {filteredSchedules && filteredSchedules.map((row) => {
                                const { id, group, contribution_date, next_contribution_date, account_number, balance, amount, position, updatedAt, createdAt } = row;
                                const { service_charge_amount, contribution_amount } = group
                                const total = service_charge_amount + contribution_amount
                                const counter = Math.floor(balance / total)
                                return (
                                    <TableRow
                                        hover
                                        key={id}
                                        tabIndex={-1}
                                    >
                                        <TableCell align="left">{balance===0?'No Action':<Button size='small' onClick={() => handleCancel(id)} variant="text">Cancel</Button>}</TableCell>
                                        <TableCell align="left">{id}</TableCell>
                                        <TableCell align="left">{group.name}</TableCell>
                                        <TableCell align="left">{group.frequency}</TableCell>
                                        <TableCell align="left">{contribution_date}</TableCell>
                                        <TableCell align="left">{next_contribution_date}</TableCell>
                                        <TableCell align="left">{account_number}</TableCell>
                                        <TableCell align="left">{counter} payment{counter <= 1 ? '' : '(s)'}</TableCell>
                                        <TableCell align="left">{fCurrency(balance)}</TableCell>
                                        <TableCell align="left">{fDateTime(createdAt)}</TableCell>
                                        <TableCell align="left">{fDateTime(updatedAt)}</TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow style={{ height: 20 * 2 }}>
                                <TableCell colSpan={8} />
                            </TableRow>
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 15, 25, 30, 35, 40, 45, 50, 80]}
                component="div"
                count={schedules.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}