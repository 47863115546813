/* eslint-disable array-callback-return */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { filter } from 'lodash';
import useIsMountedRef from 'use-is-mounted-ref';
import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';
// import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    Box,
    TextField,
    FormControlLabel,
    Alert,
    Snackbar,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    MenuItem,
    Select,
    OutlinedInput,
    ListItemText,
    InputLabel,
    Chip,
    Grid,
    Card,
    Container,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import { postData } from '../utils/generalFunctions';
import { DATESBY_ACCOUNT, PAY, STATEMENT_URL } from '../utils/urls';
import { fCurrency } from '../utils/formatNumber';
import ConfirmDialog from '../components/ConfirmDialog';



Payments.propTypes = {
    account_no: PropTypes.string,
    amount: PropTypes.number,
    callbackFunc: PropTypes.func,
    typeValue: PropTypes.string,
};

Payments.defaultProps = {
    account_no: '',
    amount: 0,
    callbackFunc: null,
    typeValue: 'contribution',
};
export default function Payments(props) {
    const { account_no, typeValue, amount, callbackFunc } = props
    const [message, setMessage] = React.useState('')
    const [dates, setDates] = React.useState([])
    const [subscriptions, setSubscriptions] = useState([]);

    const [statement, setStatement] = useState({})
    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'center',
        horizontal: 'center',
        severity: 'error'
    });

    const schema = Yup.object().shape({
        account_no: Yup.string()
            .min(3, 'Payment account number is invalid')
            .required('Payment account number is a mandatory field'),
        amount: Yup.number()
            .max(Math.floor(statement.savings_account && statement.savings_account.account_balance), `Your balance is KES${Math.floor(statement.savings_account && statement.savings_account.account_balance)}`)
            .required('Payment amount is a mandatory field'),
        reference: Yup.string()
            .min(1, 'Too short!')
            .max(200, 'Too long!')
            .required('Payment reference is a mandatory field (write anything important about this transaction)'),
        payment_date: Yup.string()
            .when("typeValue", {
                is: 'late_payment',
                then: Yup.string('Provide a date')
                    .required('Provide a date')
            }).notRequired(),
        typeValue: Yup.string('Payment type is required'),
        numberOfPayments: Yup.number('Number of payments is required')
            .when("typeValue", {
                is: 'future',
                then: Yup.number('Number of schedules payemnts is required')
                    .required('Number of schedules payemnts is required')
            }).notRequired()
    });

    const formik = useFormik({
        initialValues: {
            account_no,
            amount,
            reference: '',
            payment_date: '',
            typeValue,
            numberOfPayments: 1
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values) => {
            try {
                postData(PAY, values)
                    .then(data => {
                        if (data.status) {
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'success' });
                            formik.resetForm()
                            formik.setSubmitting(false)
                            setTimeout(() => {
                                toggleOpen()
                            }, 1500)
                        } else {
                            formik.setSubmitting(false)
                            formik.isValidating = false
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'error' });
                        }
                    });
            } catch (error) {
                setMessage(error.message)
                setState({ ...state, open: true, severity: 'error' });
                formik.setSubmitting(false)
            }
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const { severity, horizontal, vertical, isOpen } = state;

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false });
    };


    const isMountedRef = useIsMountedRef();

    const getStatement = useCallback(async () => {
        try {
            await postData(`${STATEMENT_URL}?short=choice`, null, 'GET')
                .then(data => {
                    if (data.status) {
                        if (isMountedRef.current) {
                            setSubscriptions(data.subscriptions);
                            setStatement(data)
                        }
                    }
                });


        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getStatement();
    }, [getStatement]);



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const getDates = async (account_no) => {
        if (values.typeValue === 'late_payment') {
            await postData(`${DATESBY_ACCOUNT}/${account_no}`, null, 'GET')
                .then(data => {
                    if (data.status) {
                        if (isMountedRef.current) {
                            setDates(data.dates)
                        }
                    }
                });
        }

    }

    const [open, setOpen] = React.useState(false)
    const onConfirm = () => {
        formik.submitForm()
    }

    const handleACChange = (e) => {
        formik.setFieldValue('account_no', e.target.value)
        getDates(e.target.value)
    }
    const toggleOpen = () => {
        if (open) {
            setOpen(!open)
        } else {
            const errorValues = Object.values(formik.errors)
            if (errorValues.length !== 0) {
                return errorValues.map((error) => {
                    setMessage(error)
                    setState({ ...state, isOpen: true, severity: 'error' });
                })

            }

            if (!formik.dirty) {
                setMessage("All fields are required")
                setState({ ...state, isOpen: true, severity: 'error' });
            } else {
                setOpen(!open)
            }
        }
    }
    return (
        <Page title='Chama: Payments'>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <ConfirmDialog title='Pay?' open={open} acceptText='Pay' showDismiss={false} cancelText='Leave it!' isSubmitting={isSubmitting}
                    message={`Confirm to make payment to group account number ${values.account_no}`}
                    onConfirm={onConfirm} onDismiss={toggleOpen} onDecline={toggleOpen} />

            </Backdrop>
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={20000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Container maxWidth="lg">

                <Grid item xs={12} md={12} lg={12}>
                    <Card>
                        <Stack direction="column" mr={5} ml={5} mt={5} mb={5}>
                            <FormikProvider value={formik}>
                                <Stack direction='row'>
                                    <Box sx={{ pb: 2 }}>
                                        <Typography variant="h6">Make Chama Payments</Typography>
                                    </Box>
                                </Stack>
                                <Form disabled={isSubmitting} autoComplete="on" noValidate onSubmit={handleSubmit}>
                                    <Stack direction="row" alignItems="center" justifyContent="stretch" sx={{ my: 2 }}>
                                        <FormControl>
                                            <FormLabel id="typeValue">Payment Type</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="type"
                                                name="row-radio-buttons-type"
                                                {...getFieldProps('typeValue')}
                                            >
                                                <FormControlLabel value="contribution" control={<Radio />} label="Contribution" />
                                                <FormControlLabel value="late_payment" control={<Radio />} label="Late Payment" />
                                                <FormControlLabel value="future" control={<Radio />} label="Schedule Payments (Tuliza)" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ my: 3 }} spacing={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="account_no-label">Payment A/C</InputLabel>
                                            <Select
                                                // onFocus={getDates}
                                                labelId="account_no-label"
                                                id="account_no"
                                                value={values.account_no}
                                                input={<OutlinedInput label="Payment A/C" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        <Chip key={selected} label={selected} />
                                                    </Box>
                                                )}
                                                onChange={handleACChange}
                                                // {...getFieldProps('account_no')}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem>
                                                    <Typography variant='subtitle1'>--SELECT/CLEAR-- </Typography>
                                                </MenuItem>
                                                {subscriptions && filter(subscriptions, _item => !_item.is_deleted && _item.position !== 0).map((subscription) => (
                                                    <MenuItem key={subscription.id} value={subscription.contribution_account}>
                                                        <ListItemText primary={`${subscription.contribution_account} - ${subscription.group.name} to pay KES${fCurrency(subscription.group.service_charge_amount + subscription.group.contribution_amount)}`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <TextField style={{ display: values.typeValue === 'late_payment' || values.typeValue === 'future' ? 'none' : undefined }}
                                            fullWidth
                                            label="Payment amount"
                                            {...getFieldProps('amount')}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                        />
                                        <TextField style={{ display: values.typeValue === 'future' ? undefined : 'none' }}
                                            fullWidth
                                            label="Number of Contributions"
                                            {...getFieldProps('numberOfPayments')}
                                            error={Boolean(touched.numberOfPayments && errors.numberOfPayments)}
                                            helperText={touched.numberOfPayments && errors.numberOfPayments}
                                        />
                                    </Stack>

                                    <Stack direction='column' sx={{ mb: 3 }}>

                                        <FormControl style={{ display: values.typeValue !== 'late_payment' ? 'none' : undefined }} sx={{ mb: 3 }}>

                                            <InputLabel id="date_opt-label">Late Payment Date</InputLabel>
                                            <Select
                                                labelId="date_opt-label"
                                                id="date_opt"
                                                input={<OutlinedInput label="Late Payment Date" />}
                                                {...getFieldProps('payment_date')}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem>
                                                    <Typography variant='subtitle1'> SELECT A DATE</Typography>
                                                </MenuItem>
                                                {dates && dates.map((item, index) => (
                                                    <MenuItem key={index} value={item.date}>
                                                        <ListItemText primary={item.date} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            multiline
                                            fullWidth
                                            label="Payment reference (a note e.g. Contribution)"
                                            {...getFieldProps('reference')}
                                            error={Boolean(touched.reference && errors.reference)}
                                            helperText={touched.reference && errors.reference}
                                        />
                                    </Stack>

                                    <Stack alignItems="end">
                                        <LoadingButton
                                            autoFocus
                                            size="small"
                                            onClick={toggleOpen}
                                            variant="contained"
                                            loading={isSubmitting}
                                        >
                                            Pay
                                        </LoadingButton>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Stack>
                    </Card>
                </Grid>
            </Container>
        </Page>
    );
}
