import { useState } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
import {
    Table,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Typography,
    Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, Stack
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { postData } from "../../../../utils/generalFunctions";
import { TableListHead, TableListToolbar } from '../../table';
import { ADMINS } from "../../../../utils/urls";
import { fDateTimeSuffix } from "../../../../utils/formatTime";

const TABLE_HEAD = [
    { id: 'id', label: 'Loan ID', alignRight: false },
    { id: 'loan_account_number', label: 'Loan A/C', alignRight: false },
    { id: 'member_name', label: 'Name', alignRight: false },
    { id: 'requested_amount', label: 'Amount Requested', alignRight: false },
    { id: 'loan_rate', label: 'Loan Rate', alignRight: false },
    { id: 'amount_approved', label: 'Amount Approved', alignRight: false },
    { id: 'amount_due', label: 'Amount Due', alignRight: false },
    { id: 'interest_due', label: 'Interest Due', alignRight: false },
    { id: 'total_amount_paid', label: 'Total Paid', alignRight: false },
    { id: 'next_due_date', label: 'Next Due Date', alignRight: false },
    { id: 'reducing_balance_amount', label: 'Redcng Bal Amt.', alignRight: false },
    { id: 'payment_frequency', label: 'Payment Freq', alignRight: false },
    { id: 'loan_due_date', label: 'Due On', alignRight: false },
    { id: 'approved_date', label: 'Approved On', alignRight: false },
    { id: 'createdOn', label: 'Requested On', alignRight: false },
    { id: '', label: '', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


Loans.propTypes = {
    loans: PropTypes.array,
    setValue: PropTypes.func,
    getLoans: PropTypes.func,
}

Loans.defaultProps = {
    loans: []
}

export default function Loans({ loans, setValue, getLoans }) {
    const [state, setState] = useState({
        isOpen: false,
        message: ''
    });
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const { isOpen, message } = state;

    const filteredManagers = applySortFilter(loans, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredManagers.length === 0;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - loans.length) : 0;


    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_request) => `${_request.id}`.indexOf(query) !== -1
                || `${_request.mobile}`.indexOf(query) !== -1
                || `${_request.member_name}`.toLowerCase().indexOf(query) !== -1
                || `${_request.requested_amount}`.indexOf(query) !== -1
                || `${_request.loan_account_number.toLowerCase()}`.indexOf(query) !== -1
                || `${_request.region.toLowerCase()}`.indexOf(query) !== -1
                || `${_request.national_number}`.indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = loans.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'info' });
    };

    const toggleActiveChange = (item) => {
        item.active = !item.active
        postData(ADMINS, item, 'PUT').then((response) => {
            if (!response.status) {
                setState({ ...state, isOpen: true, message: response.message })

            }
            getLoans()
        })
    };

    const toggleSignatoryChange = (item) => {
        item.is_signatory = !item.is_signatory
        postData(ADMINS, item, 'PUT').then((response) => {
            if (!response.status) {
                setState({ ...state, isOpen: true, message: response.message })
            }
            getLoans()
        })
    };

    const deleteManager = (item) => {
        item.is_signatory = !item.is_signatory
        postData(`${ADMINS}/${item.id}`, item, 'DELETE').then((response) => {
            if (response.status) {
                getLoans()
            } else {
                setState({ ...state, isOpen: true, message: response.message })
            }
        })
    };
    return (
        <>

            <Dialog
                open={isOpen}
                onClose={handleSnackClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Info
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' sm onClick={handleSnackClose}>OK</Button>
                </DialogActions>
            </Dialog>

            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                childComponent={<Typography>{filteredManagers.length}</Typography>}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={loans.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                            childComponent
                        />
                        <TableBody>
                            {filteredManagers && filteredManagers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id,  loan_account_number, member_name,  requested_amount,
                                        loan_rate, amount_approved, amount_due, interest_due, total_amount_paid, next_due_date, reducing_balance_amount,
                                        payment_frequency, loan_due_date, approved_date, createdOn, updatedOn } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell align="left">{id}</TableCell>
                                            <TableCell align="left">{loan_account_number}</TableCell>
                                            <TableCell align="left">{member_name}</TableCell>
                                            <TableCell align="left">{requested_amount}</TableCell>
                                            <TableCell align="left">{loan_rate}</TableCell>
                                            <TableCell align="left">{amount_approved}</TableCell>
                                            <TableCell align="left">{amount_due}</TableCell>
                                            <TableCell align="left">{interest_due}</TableCell>
                                            <TableCell align="left">{total_amount_paid}</TableCell>
                                            <TableCell align="left">{next_due_date}</TableCell>
                                            <TableCell align="left">{reducing_balance_amount}</TableCell>
                                            <TableCell align="left">{payment_frequency}</TableCell>
                                            <TableCell align="left">{loan_due_date}</TableCell>
                                            <TableCell align="left">{approved_date}</TableCell>
                                            <TableCell align="left">{createdOn}</TableCell>
                                            <TableCell align="right">
                                                <Stack direction='row'>
                                                    <Button color='info' variant='text' size="small">No Action</Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={loans.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}