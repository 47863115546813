/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */

import { filter } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer, TablePagination
} from '@mui/material';
import { fDateTime } from '../../../../utils/formatTime';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { TableListHead, TableListToolbar } from '../../table';
import { fNumber } from '../../../../utils/formatNumber';
import FundraisingOptions from '../../table/FundraisingOptions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'paybill', label: 'Paybill No.', alignRight: false },
    { id: 'account_number', label: 'A/C No.', alignRight: false },
    { id: 'budget', label: 'Budget', alignRight: false },
    // { id: 'current_offline_contributions', label: 'CIH', alignRight: false },
    // { id: 'expenses', label: 'Expenses', alignRight: false },
    { id: 'total_contributions', label: 'Contributions', alignRight: false },
    { id: 'total_pledges', label: 'Pledges', alignRight: false },
    // { id: 'pledges_paid', label: 'Pledges Paid', alignRight: false },
    { id: 'account_balance', label: 'Balance', alignRight: false },
    { id: 'total_withdrawals_derived', label: 'Withdrawals', alignRight: false },
    { id: 'deadline', label: 'Deadline', alignRight: false },
    { id: 'createdAt', label: 'Started On', alignRight: false },
    { id: '', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_fundraiser) => _fundraiser.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
            || `${_fundraiser.id}`.indexOf(query) !== -1
            || `${_fundraiser.purpose}`.indexOf(query) !== -1
            || `${_fundraiser.description}`.indexOf(query) !== -1
            || `${_fundraiser.budget}`.indexOf(query) !== -1);
    }

    return stabilizedThis.map((el) => el[0]);
}
Fundraisings.propTypes = {
    fundraisings: PropTypes.array,
    tap: PropTypes.func,
}
Fundraisings.defaultProps = {
    fundraisings: []
}
export default function Fundraisings({ fundraisings, tap }) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = fundraisings.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fundraisings.length) : 0;

    const filteredRecords = applySortFilter(fundraisings, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredRecords.length === 0;


    return (
        <>
            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
            />
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={fundraisings.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                        />
                        <TableBody>
                            {filteredRecords && filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id, description, paybill, current_offline_contributions, account_number, budget, deadline, total_contributions, total_pledges, pledges_paid, expenses, account_balance, total_withdrawals_derived, createdAt } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;
                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}

                                        >
                                            {/* <TableCell align="left">{id}</TableCell> */}
                                            <TableCell align="left">{description}</TableCell>
                                            <TableCell align="left">{paybill}</TableCell>
                                            <TableCell align="left">{account_number}</TableCell>
                                            <TableCell align="left">{fNumber(budget)}</TableCell>
                                            {/* <TableCell align="left">{fNumber(current_offline_contributions)}</TableCell>
                                            <TableCell align="left">{fNumber(expenses)}</TableCell> */}
                                            <TableCell align="left">{fNumber(total_contributions)}</TableCell>
                                            <TableCell align="left">{fNumber(total_pledges)}</TableCell>
                                            {/* <TableCell align="left">{fNumber(pledges_paid)}</TableCell> */}
                                            <TableCell align="left">{fNumber(account_balance)}</TableCell>
                                            <TableCell align="left">{fNumber(total_withdrawals_derived)}</TableCell>
                                            <TableCell padding="normal">
                                                {fDateTime(deadline)}
                                            </TableCell>
                                            <TableCell padding="normal">
                                                {fDateTime(createdAt)}
                                            </TableCell>

                                            <TableCell align="left">
                                                <FundraisingOptions item={row} />
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={fundraisings.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}
