// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'Make Payments',
    icon: getIcon('mdi:transfer'),
    children: [
      {
        title: 'Transfer Money',
        path: '/payments/transfer-money'
      },
      {
        title: 'Deposit Money',
        path: '/payments/recharge'
      },
      {
        title: 'Lipa Chama',
        path: '/payments/chama-payments'
      },
      {
        title: 'Lipiana Chama',
        path: '/payments/lipiana-chama-payments'
      },

    ]
  },
  {
    title: 'Chamas',
    icon: getIcon('eos-icons:product-subscriptions'),
    children: [
      {
        title: 'Membership Chamas',
        path: '/dashboard/subscriptions',
        icon: getIcon('eos-icons:product-subscriptions')
      },
      {
        title: 'Managed Chamas',
        path: '/dashboard/managed-chamas',
        icon: getIcon('eos-icons:product-subscriptions')
      }
    ]
  },
  {
    title: 'Tuliza Payments',
    icon: getIcon('wpf:future'),
    children: [
      {
        title: 'Scheduled Payments',
        path: '/dashboard/tuliza-payments'
      }
    ]
  },
  // {
  //   title: 'Utilities & Bills',
  //   icon: getIcon('fa6-solid:money-bills'),
  //   children: [
  //     {
  //       title: 'Buy Airtime',
  //       path: '/login'
  //     },
  //   ]
  // },
  {
    title: 'Statements',
    path: '/dashboard/statements',
    icon: getIcon('icon-park-outline:transaction')
  }
];

export default sidebarConfig;
