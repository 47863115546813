import { useState } from "react";
// import PropTypes from 'prop-types'
import * as React from 'react';
import useIsMountedRef from 'use-is-mounted-ref';
import { filter } from "lodash";
import { useDispatch,useSelector } from "react-redux";
import {
    Table,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Card} from '@mui/material';
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { postData } from "../../../../utils/generalFunctions";
import { TableListHead, TableListToolbar } from '../../table';
import { FUNDRAISING } from "../../../../utils/urls";
import { fDateTime } from "../../../../utils/formatTime";
import { fCurrency } from "../../../../utils/formatNumber";

const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    // { id: 'fundraiserId', label: 'Fundraiser ID', alignRight: false },
    { id: 'description', label: 'Reference', alignRight: false },
    { id: 'pledge_account_number', label: 'Pledge A/C no', alignRight: false },
    { id: 'pledge_amount', label: 'Pledge Amount', alignRight: false },
    { id: 'amount_paid', label: 'Amount Paid', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'full_name', label: 'Member name', alignRight: false },
    { id: 'mobile_number', label: 'Mobile no', alignRight: false },
    { id: 'TransID', label: 'Transaction ID', alignRight: false },
    { id: 'createdAt', label: 'Created On', alignRight: false }
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


export default function FundraisingTransactions() {
    const dispatch = useDispatch()
    const fundraiser = useSelector(state => state.fundraiser) 
    const [pages, setPages] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [transfers, setTransactions] = React.useState([]);
    const isMountedRef = useIsMountedRef();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [pageSelect, setPageSelect] = useState(1);

    const filteredTransfers = applySortFilter(transfers, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredTransfers.length === 0;
    const getTransfers = React.useCallback(async () => {
        try {
            if (isMountedRef.current) {
                await contibutionTransactions()
            }
        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);

    React.useEffect(() => {
        getTransfers() 
    }, [])
    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {

            return filter(array, (_transfers) => `${_transfers.id}`.indexOf(query) !== -1
                || `${_transfers.transaction_code}`.toLowerCase().indexOf(query) !== -1
                || `${_transfers.reference}`.toLowerCase().indexOf(query) !== -1
                || `${_transfers.member_no}`.toLowerCase().indexOf(query) !== -1
                || `${_transfers.query_date}`.indexOf(query) !== -1
                || `${_transfers.from.description}`.toLowerCase().indexOf(query.toLowerCase()) !== -1
                || `${_transfers.to.description}`.toLowerCase().indexOf(query.toLowerCase()) !== -1
                || `${_transfers.amount}`.indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        contibutionTransactions(1, parseInt(event.target.value, 10))
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        let page = 0
        if (newPage === 0) {
            page = 1
        } else {
            page = newPage + 1
        }
        setPageSelect(page)
        contibutionTransactions(page, rowsPerPage).then(() => {
            setPage(newPage);
        })
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = transfers.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const contibutionTransactions = async (page = 1, page_size = 5) => {
        dispatch({ type: 'set', transfers: [] })
        await postData(`${FUNDRAISING}/contributions?fundraiserId=${fundraiser.id}&page=${page}&page_size=${page_size}`, {}, 'GET').then((response) => {
            if (response.status) {
                // "pages":2,"count":10,
                setTransactions(response.contributions)
                setCount(response.count)
                setPages(response.pages)
                dispatch({ type: 'set', transfers: response.contributions })
            } else {
                dispatch({ type: 'set', transfers: [] })
            }
        })
    }

    return (
        <Card sx={{p:2}}>
            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                childComponent={ <Button
                    variant="text"
                    onClick={() => contibutionTransactions(pageSelect, rowsPerPage)}
                    startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                    refresh
                </Button>}
            />

            < Scrollbar >
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={transfers.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                        />
                        <TableBody>
                            {filteredTransfers && filteredTransfers.map((row) => {
                                const { id,type,amount_paid, fundraiserId, description, pledge_account_number, pledge_amount, full_name, mobile_number, TransID, createdAt } = row;
                                const isItemSelected = selected.indexOf(id) !== -1;
                                return (
                                    <TableRow
                                        hover
                                        key={id}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                    >
                                        <TableCell align="left">{id}</TableCell>
                                        {/* <TableCell align="left">{fundraiserId}</TableCell> */}
                                        <TableCell align="left">{description}</TableCell>
                                        <TableCell align="left">{pledge_account_number||'N/A'}</TableCell>
                                        <TableCell align="left">{fCurrency(pledge_amount)}</TableCell>
                                        <TableCell align="left">{fCurrency(amount_paid)}</TableCell>
                                        <TableCell align="left">{type===1?'Contribution':'Pledge'}</TableCell>
                                        <TableCell align="left">{full_name}</TableCell>
                                        <TableCell align="left">{mobile_number}</TableCell>
                                        <TableCell align="left">{TransID||'N/A'}</TableCell>
                                        <TableCell align="left">{fDateTime(createdAt)}</TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow style={{ height: 20 * 2 }}>
                                <TableCell colSpan={8} />
                            </TableRow>
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar >

            <TablePagination
                rowsPerPageOptions={[5, 15, 25, 30, 35, 40, 45, 50, 80]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
    )
}