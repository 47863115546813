/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/';
import { filter } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
    Table,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer, TablePagination, Stack, Button,
    Snackbar, Alert, TextField
} from '@mui/material';
import { sentenceCase } from 'change-case';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { TableListHead, TableListToolbar, GroupRowOptions } from '../../table';
import { postData } from '../../../../utils/generalFunctions';
import { fNumber } from '../../../../utils/formatNumber';
import GroupEdit from '../../app/GroupEdit';
import { CHANGESTATUS } from '../../../../utils/urls';
import ConfirmDialog from '../../../../components/ConfirmDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'account_number', label: 'A/C', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'system_amount', label: 'System Fee', alignRight: false },
    { id: 'frequency', label: 'Frequency', alignRight: false },
    { id: 'contribution_date', label: 'Contribution Date', alignRight: false },
    { id: 'contribution_amount', label: 'Contribution amount', alignRight: false },
    { id: 'has_whatsapp', label: 'WhatsApp Integrated?', alignRight: false },
    { id: 'charge_fines', label: 'Charge Penalty', alignRight: false },
    { id: 'state', label: 'Change Status', alignRight: false },
    { id: '', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_group) => _group.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
            || `${_group.id}`.indexOf(query) !== -1
            || `${_group.contribution_amount}`.indexOf(query) !== -1
            || `${_group.system_amount}`.indexOf(query) !== -1
            || `${_group.service_charge_amount}`.indexOf(query) !== -1);
    }

    return stabilizedThis.map((el) => el[0]);
}
GroupsListing.propTypes = {
    groups: PropTypes.array,
    default_cron_str: PropTypes.string,
    tap: PropTypes.func,
}
GroupsListing.defaultProps = {
    groups: [],
    default_cron_str: ''
}
export default function GroupsListing({ groups, default_cron_str, tap }) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [editOpen, setEditOpen] = React.useState(false);
    const [selectedGroup, setSelectedGroup] = React.useState({});
    const [value, setValue] = React.useState(new Date());
    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'left',
        severity: 'error'
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = groups.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groups.length) : 0;

    const filteredGroups = applySortFilter(groups, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredGroups.length === 0;
    const [confirmMessage, setConfirmMessage] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const [group, setGroup] = React.useState(null)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const handleOnDecline = () => {
        setOpenConfirm(false)
    }

    const handleOnConfirm = () => {
        setIsSubmitting(true)
        postData(`${CHANGESTATUS}/${group.id}`, {}).then((resp) => {
            setOpenConfirm(false)
            setIsSubmitting(false)
            if (resp.status) {
                tap()
                setState({ ...state, isOpen: true, severity: 'success' });
            } else {
                setState({ ...state, isOpen: true, severity: 'success' });
            }
            setMessage(resp.message)
        })

    }

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'error' });
    };

    const handleStatusChange = async (group) => {
        setConfirmMessage(`You are about to ${group.active ? 'deactivate' : 'activate'} the group ${group.name}, Would you like to complete this action?`)
        setOpenConfirm(true)
        setGroup(group)
    }
    const { severity, horizontal, vertical, isOpen } = state;

    const dateChanged = (group) => {

    }

    const datePicker =(row)=> (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                value={row.contribution_date}
                onChange={() => dateChanged(row)}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    )
    return (
        <>
            <ConfirmDialog isSubmitting={isSubmitting} onDismiss={handleOnDecline} cancelText='No Please!' acceptText='Yes Commit!' title='Change Status' dismissText='No Please!' showDismiss={false} open={openConfirm} message={confirmMessage} onConfirm={handleOnConfirm} onDecline={handleOnDecline} />
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={10000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
            />
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={groups.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                        />
                        <TableBody>
                            {filteredGroups && filteredGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id, active, system_amount, name, frequency, contribution_amount, service_charge_amount,
                                        account_number, start_hour, charge_fines, charge_on_percent,
                                        fine_percent, fine_charge, has_whatsapp, contribution_date } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;
                                    const amount = contribution_amount + service_charge_amount;

                                    const suffix = start_hour >= 12 ? "pm" : "am";
                                    const hour = ((21 + 11) % 12 + 1) + suffix
                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}

                                        >
                                            <TableCell align="left">{id}</TableCell>
                                            <TableCell align="left">{account_number}</TableCell>
                                            <TableCell align="left">{name}{" "}
                                                <Label
                                                    variant="ghost"
                                                    color={(!active && 'error') || 'success'}
                                                >
                                                    {sentenceCase((!active && ' Inactive') || ' Active')}
                                                </Label></TableCell>
                                            <TableCell align="left">{system_amount}</TableCell>
                                            <TableCell align="left"><Typography variant='caption'> {frequency && frequency.toUpperCase()}</Typography></TableCell>
                                            <TableCell align="left">
                                                {contribution_date}
                                            </TableCell>
                                            <TableCell align="left">{fNumber(amount)}</TableCell>
                                            <TableCell >
                                                <Checkbox
                                                    checked={has_whatsapp}
                                                />
                                            </TableCell>

                                            <TableCell padding="normal">
                                                <Stack direction='row'>
                                                    <Checkbox
                                                        checked={charge_fines}
                                                    /> {charge_fines ? <Typography variant='body2' sx={{ pt: 1 }}>{`KES${charge_on_percent ? (amount * fine_percent / 100) : fNumber(fine_charge)} from ${hour}`}</Typography> : ''}
                                                </Stack>
                                            </TableCell>
                                            <TableCell padding="normal">
                                                <Button onClick={() => handleStatusChange(row)} color={active ? 'error' : 'info'} variant='outlined' size='small'>{active ? 'Deactivate' : 'Activate'}</Button>
                                            </TableCell>
                                            <TableCell align="left">
                                                <GroupRowOptions group={row} setEditOpen={setEditOpen} setSelectedGroup={setSelectedGroup} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={groups.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <GroupEdit refreshSystem={tap} group={selectedGroup} default_cron_str={default_cron_str} open={editOpen} setOpen={setEditOpen} />
        </>
    );
}
