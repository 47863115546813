import { useState } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
import {
    Table,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Typography,
    Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { postData } from "../../../../utils/generalFunctions";
import { TableListHead, TableListToolbar } from '../../table';
import { ADMINS, DELETE_SUBSCRIBER } from "../../../../utils/urls";
import { fDateTimeSuffix } from "../../../../utils/formatTime";

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'mobile', label: 'Mobile', alignRight: false },
    { id: 'is_signatory', label: 'Signatory', alignRight: false },
    { id: 'active', label: 'Active', alignRight: false },
    { id: 'createdAt', label: 'Created On', alignRight: false },
    { id: 'updatedAt', label: 'Last Updated On', alignRight: false },
    { id: '', label: '', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


ManagersTable.propTypes = {
    managers: PropTypes.array,
    setValue: PropTypes.func,
    getManagers: PropTypes.func,
}

ManagersTable.defaultProps = {
    managers: []
}

export default function ManagersTable({ managers, setValue, getManagers }) {
    const [state, setState] = useState({
        isOpen: false,
        message: ''
    });
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const { isOpen, message } = state;

    const filteredManagers = applySortFilter(managers, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredManagers.length === 0;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - managers.length) : 0;


    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_mebers) => `${_mebers.id}`.indexOf(query) !== -1
                || `${_mebers.mobile}`.indexOf(query) !== -1
                || `${_mebers.name}`.toLowerCase().indexOf(query) !== -1
                || `${_mebers.position}`.indexOf(query) !== -1
                || `${_mebers.region.toLowerCase()}`.indexOf(query) !== -1
                || `${_mebers.contribution_account}`.indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = managers.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'info' });
    };

    const toggleActiveChange = (item) => {
        item.active = !item.active
        postData(ADMINS, item, 'PUT').then((response) => {
            if (!response.status) {
                setState({ ...state, isOpen: true, message: response.message })

            }
            getManagers()
        })
    };

    const toggleSignatoryChange = (item) => {
        item.is_signatory = !item.is_signatory
        postData(ADMINS, item, 'PUT').then((response) => {
            if (!response.status) {
                setState({ ...state, isOpen: true, message: response.message })
            }
            getManagers()
        })
    };

    const deleteManager = (item) => {
        item.is_signatory = !item.is_signatory
        postData(`${ADMINS}/${item.id}`, item, 'DELETE').then((response) => {
            if (response.status) {
                getManagers()
            } else {
                setState({ ...state, isOpen: true, message: response.message })
            }
        })
    };
    return (
        <>

            <Dialog
                open={isOpen}
                onClose={handleSnackClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Info
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' sm onClick={handleSnackClose}>OK</Button>
                </DialogActions>
            </Dialog>

            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                childComponent={<Typography>{filteredManagers.length}</Typography>}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={managers.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                            childComponent
                        />
                        <TableBody>
                            {filteredManagers && filteredManagers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id, mobile, name, is_signatory, active, updatedAt, createdAt } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell align="left">{name}</TableCell>
                                            <TableCell align="left">{mobile}</TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onChange={() => toggleSignatoryChange(row)}
                                                    checked={is_signatory}
                                                />
                                            </TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onChange={() => toggleActiveChange(row)}
                                                    checked={active}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{fDateTimeSuffix(createdAt)}</TableCell>
                                            <TableCell align="left">{fDateTimeSuffix(updatedAt)}</TableCell>
                                            <TableCell align="right">
                                                <Button color='error' onClick={() => deleteManager(row)} variant='text' size="small">Remove</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={managers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}