// const REQ_BASE_URL ='http://localhost:9000/api' 

const REQ_BASE_URL ='https://centron.co.ke/api'

const PAYMENTS_BASEURL = `${REQ_BASE_URL}/payments`

export const REGISTER_URL = `${REQ_BASE_URL}/auth/sign-up`

export const SEND_OTP = `${REQ_BASE_URL}/auth/authenticate-request`

export const VALIDATE_OTP_SIGN_UP = `${REQ_BASE_URL}/auth/validateOTPOnSignUp`

export const VALIDATE_OTP_SIGN_IN = `${REQ_BASE_URL}/auth/validateOTPOnSignIn`

export const AUTH_URL = `${REQ_BASE_URL}/auth/sign-in`;

export const BANKS = `${REQ_BASE_URL}/v1/bank/wpg/all-banks`

export const WITHDRAWAL_OPTIONS = `${REQ_BASE_URL}/payment-options/withdrawal-options`

export const STATEMENT_URL = `${REQ_BASE_URL}/accounts/statements`;

export const HYBRID_GROUP_CREATE = `${REQ_BASE_URL}/groups/hybrid`;

export const GROUP_INFO = `${REQ_BASE_URL}/groups/hybrid/statement`;

export const CANCEL_SCHEDULE = `${REQ_BASE_URL}/groups/hybrid/cancel-schedule`;

export const CREATE_APPROVAL_REQUEST = `${REQ_BASE_URL}/groups/hybrid/schedule-approval/`;

export const MEMBERS_BY_GROUPID = `${REQ_BASE_URL}/groups/hybrid/group-members/`

export const APPROVALS = `${REQ_BASE_URL}/groups/hybrid/approvals/`

export const SIGNAPPROVAL = `${REQ_BASE_URL}/checkouts/signatories_request_approvals/process`

export const CONTRIBUTIONS = `${REQ_BASE_URL}/groups/hybrid/contributions`

export const DELETE_SUBSCRIBER = `${REQ_BASE_URL}/groups/hybrid/delete-subscriber/`

export const SEARCH_CLIENT = `${REQ_BASE_URL}/clients/search`

export const SUBSCRIBE_TO_GROUP = `${REQ_BASE_URL}/clients/subscribe`

export const ADMIN_TO_GROUP = `${REQ_BASE_URL}/groups/hybrid/make-admin`

export const ADMINS = `${REQ_BASE_URL}/groups/hybrid/managers`

export const CONTRIBUTION_DATES = `${REQ_BASE_URL}/groups/contribution-dates`

export const PROFITS = `${REQ_BASE_URL}/checkouts/move_profits`

export const TRANSFERS = `${REQ_BASE_URL}/groups/hybrid/transfers`

export const GET_ALL_LOANS = `${REQ_BASE_URL}/table-banking/all_loans`

export const DATESBY_ACCOUNT = `${PAYMENTS_BASEURL}/contribution-dates`

export const PAY = `${PAYMENTS_BASEURL}/make-payments`

export const DEPOSIT_OPTIONS = `${PAYMENTS_BASEURL}/deposit-methods`
 
export const DEPOSIT = `${PAYMENTS_BASEURL}/deposit-money`

export const TRANSFER_MONEY = `${PAYMENTS_BASEURL}/transfer-money`

export const GET_TRANSFER_COST = `${PAYMENTS_BASEURL}/transfer-cost`

export const STOP_SCHEDULE = `${REQ_BASE_URL}/dashboard/page/schedule_to_Wallet`

export const APPROVE_LOANS = `${REQ_BASE_URL}/table-banking/approve_loans`

export const FUNDRAISING = `${REQ_BASE_URL}/fundraising`;

export const CHANGESTATUS = `${REQ_BASE_URL}/groups/hybrid/change-group-status`;

export const UPLOAD_LOANS = `${REQ_BASE_URL}/table-banking/upload_loans`;
