import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack, TextField, IconButton,
  Snackbar,
  Alert, InputAdornment, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { postData, setStorageItem } from '../../../utils/generalFunctions';
// component
import Iconify from '../../../components/Iconify';
import {  SEND_OTP, VALIDATE_OTP_SIGN_UP } from '../../../utils/urls';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [message, setMessage] = useState('')
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    severity: 'error',
    validationSMS: ''
  });
  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    mobile: Yup.string().min(9, 'Too Short!').max(9, 'Too Long!').required('Please provide a valid mobile number'),
    last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    national_number: Yup.string().min(8, 'Too Short!').max(15, 'Too Long!').required('Passport/ID number required'),
    region: Yup.string().notRequired(),
    email: Yup.string().email('Email must be a valid email address').notRequired(),
    password: Yup.string().min(4).required('Password is required'),
    OTP: Yup.string().when('validate', {
      is: true,
      then: Yup.string().required('OTP is required')
    }).notRequired(),
    validate: Yup.bool().required('This field is required'),
    confirm_password: Yup
      .string()
      .required('Confirm password is required')
      .oneOf(
        [Yup.ref('password'), null],
        'Passwords are not the same!',
      )
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      national_number: '',
      region: '',
      mobile: '',
      password: '',
      OTP: '',
      validate: false,
      scope: 'registration',
      country_code: '254'
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      if (!values.validate) {
        postData(SEND_OTP, values)
          .then(data => {
            if (data.status) {
              formik.setFieldValue('validate', true)
              formik.setSubmitting(false)
              setMessage(data.message)
              setState({ ...state, open: true, validationSMS: data.message, severity: 'success' });
            } else {
              setMessage(data.message)
              setState({ ...state, open: true, severity: 'error' });
              formik.setSubmitting(false)
            }
          }).catch(error => {
            setMessage(`Server not reachable,please try again later. ...${error.message}`)
            setState({ ...state, open: true, severity: 'error' });
            formik.setSubmitting(false)
          });
      } else {
        postData(VALIDATE_OTP_SIGN_UP, values)
          .then(data => {
            if (data.status) {
              const { user, profile, refresh_token, access_token } = data.data
              const { user_id } = user
              setStorageItem('auth_token', access_token)
              setStorageItem('refresh_token', refresh_token)
              setStorageItem('display_name', profile.account_.name)
              setStorageItem('acount_', JSON.stringify(profile))
              setStorageItem('user_id', user_id)
              setStorageItem('savings_id', user.default_savings_id)
              setStorageItem('user', JSON.stringify(user))
              setMessage(data.message)
              setState({ ...state, open: true, severity: 'success' });
              formik.setSubmitting(false)
              navigate('/dashboard/app', { replace: true });
            } else {
              if(data.navigate){
                formik.setFieldValue('validate', false)
              }
              formik.setFieldValue('OTP', '')
              setMessage(data.message)
              setState({ ...state, open: true, severity: 'error' });
              formik.setSubmitting(false)
            }
          }).catch(error => {
            setMessage(`Server not reachable,please try again later. ...${error.message}`)
            setState({ ...state, open: true, severity: 'error' });
            formik.setSubmitting(false)
          });
      }

    }
  });

  const { errors, touched, handleSubmit, isSubmitting, values, getFieldProps } = formik;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ ...state, open: false });
  };

  const { vertical, horizontal, severity, open } = state;

  const handleMobileChange = (e) => {
    formik.setFieldValue('mobile', e.target.value.replaceAll(" ", '').substr(-9))
  }


  const dataStack = (
    <Stack spacing={3}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          fullWidth
          label="First name"
          {...getFieldProps('first_name')}
          error={Boolean(touched.first_name && errors.first_name)}
          helperText={touched.first_name && errors.first_name}
        />

        <TextField
          fullWidth
          label="Last name"
          {...getFieldProps('last_name')}
          error={Boolean(touched.last_name && errors.last_name)}
          helperText={touched.last_name && errors.last_name}
        />
      </Stack>

      <TextField
        fullWidth
        autoComplete="email"
        type="email"
        label="Email address"
        {...getFieldProps('email')}
        error={Boolean(touched.email && errors.email)}
        helperText={touched.email && errors.email}
      />

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0}>
        <TextField
          fullWidth
          autoComplete="username"
          type="tel"
          label="Telephone number (WhatsApp enabled number preffered) *"
          // {...getFieldProps('mobile')}
          onChange={handleMobileChange}
          value={values.mobile}
          error={Boolean(touched.mobile && errors.mobile)}
          helperText={touched.mobile && errors.mobile}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Typography>+254</Typography>
              </InputAdornment>
            )
          }}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          fullWidth
          autoComplete="national_number"
          type="text"
          label="Passport/Nationa ID number"
          {...getFieldProps('national_number')}
          error={Boolean(touched.national_number && errors.national_number)}
          helperText={touched.national_number && errors.national_number}
        />

        <TextField
          fullWidth
          autoComplete="region"
          type="text"
          label="Region"
          {...getFieldProps('region')}
          error={Boolean(touched.region && errors.region)}
          helperText={touched.region && errors.region}
        />
      </Stack>
      <TextField
        fullWidth
        autoComplete="current-password"
        type={showPassword ? 'text' : 'password'}
        label="Password"
        {...getFieldProps('password')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          )
        }}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
      />

      <TextField
        fullWidth
        autoComplete="current-password"
        type={showPasswordConfirm ? 'text' : 'password'}
        label="Confrim Password"
        {...getFieldProps('confirm_password')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => setShowPasswordConfirm((prev) => !prev)}>
                <Iconify icon={showPasswordConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          )
        }}
        error={Boolean(touched.confirm_password && errors.confirm_password)}
        helperText={touched.confirm_password && errors.confirm_password}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Register
      </LoadingButton>
    </Stack>
  )

  const validationsStack = (
    <Stack spacing={3}>
      <TextField
        fullWidth
        autoComplete="username"
        type="tel"
        disabled
        value={state.validationSMS}
      />
      <TextField
        fullWidth
        autoComplete="OTP"
        type="text"
        label="One Time Password"
        {...getFieldProps('OTP')}
        error={Boolean(touched.OTP && errors.OTP)}
        helperText={touched.OTP && errors.OTP}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        color='secondary'
        variant="contained"
        loading={isSubmitting}
      >
        Validate Phone Number
      </LoadingButton>
    </Stack>
  )
  return (
    <FormikProvider value={formik}>
      <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {values.validate ? validationsStack : dataStack}
      </Form>
    </FormikProvider>
  );
}
