/* eslint-disable-line no-unused-vars */
/* eslint-disable-file react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import useIsMountedRef from 'use-is-mounted-ref';
import { Container } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { STATEMENT_URL } from '../utils/urls';
// components
import Page from '../components/Page';
import {
  AppGroups
} from '../sections/@dashboard/app';
import { postData } from '../utils/generalFunctions';

// ----------------------------------------------------------------------

export default function ManagedChamas() {
  const [statement, setStatement] = useState({});
  const [groups, setGroups] = useState([])

  const dispatch = useDispatch()

  const isMountedRef = useIsMountedRef();

  const getStatement = useCallback(async () => {
    try {
      await postData(STATEMENT_URL, null, 'GET')
        .then(data => {
          if (data.status) {

            if (isMountedRef.current) {
              setStatement(data);
              dispatch({ type: 'set', statement: data })

              createObject(data)
            }
          }
        });


    } catch (err) {
      console.log(err.message)
    }
  }, [isMountedRef]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getStatement();
  }, [getStatement]);


  const createObject = (data) => {
    let list = []
    list = data.groups.map(({ chama }) => {
      const row = {
        id: chama.id,
        savingsId: chama.savings_id,
        name: chama.name,
        frequency: chama.frequency,
        contribution_amount: chama.contribution_amount,
        service_charge_amount: chama.service_charge_amount,
        system_amount: chama.system_amount,
        account_number: chama.account_number,
        start_hour: chama.start_hour,
        charge_fines: chama.charge_fines,
        charge_on_percent: chama.charge_on_percent,
        fine_percent: chama.fine_percent,
        fine_charge: chama.fine_charge,
        has_whatsapp: chama.has_whatsapp,
        group_image_url:chama.group_image_url,
        account_type: chama.account_type,
        account_status: chama.status,
        interest_rate: chama.interest_rate,
        cronJob: chama.cron_expression,
        whatsapp_invite_link: `${chama.whatsapp_invite_link.substr(0, 10)}...`,
        whatsapp_invite_copy: `${chama.whatsapp_invite_link}`,        
        active: chama.active,
        status: '',
      }

      return row
    })
    setGroups(list)
  }

  return (
    <Page title={`Chama: Managed Chamas | ${statement.app_name && statement.app_name}`}>
      <Container maxWidth="xl">        
        <AppGroups tap={getStatement} default_cron_str={statement && statement.default_cron_str} groups={groups} />
      </Container>
    </Page>
  );
}
