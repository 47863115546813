/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import useIsMountedRef from 'use-is-mounted-ref';
import { filter } from "lodash";
import * as React from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
// material
import {
    Stack,
    Button,
    Typography,
    Card,
    Tabs,
    Tab,
    Alert,
    Snackbar,
    Checkbox
} from '@mui/material';
import Iconify from '../../../../components/Iconify';
import { a11yProps, getStorageItem, setStorageItem } from '../../../../utils/generalFunctions';
import SubscribersTable from '../@tables/Members';
import TabPanel from '../TabPanel';
import CreateSubscription from './create'


StakeHolders.propTypes = {
    getGroupStatus: PropTypes.func
}


export default function StakeHolders({ getGroupStatus }) {
    const data = useSelector(state => state.selectedGroup)
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'info',
        message: <Typography>You can change a member status as received by clicking on inidvidual checkboxes (<Checkbox />) under has received? column it should turn to <Checkbox checked /> and a notification will be sent to the linked WhatsApp group.</Typography>
    });
    const isMountedRef = useIsMountedRef();

    const decideAlert = React.useCallback(async () => {
        try {
            if (isMountedRef.current) {
                const show_received_instructions = getStorageItem('show_received_instructions')

                if (show_received_instructions === '') {
                    setState({ ...state, isOpen: true })
                }
            }
        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);
    React.useEffect(() => {
        decideAlert()
    }, [decideAlert])

    const openCreate = () => {
        setOpen(true)
    }

    const { severity, horizontal, vertical, message, isOpen } = state;
    const handleSnackClose = () => {
        setStorageItem('show_received_instructions', false)
        setState({ ...state, isOpen: false, severity: 'info' });
    };
    return (
        <Card>
            <CreateSubscription open={open} setOpen={setOpen} getGroupStatus={getGroupStatus} />
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={5000000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '50%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mr={3} ml={3} mt={3} mb={3}>
                <Typography variant="body2" gutterBottom>Group Members</Typography>
                <Stack spacing={1} direction="row">
                    <Button
                        sx={{ display: value === 0 ? '' : 'none' }}
                        variant="contained"
                        onClick={openCreate}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        create
                    </Button>
                    <Button
                        variant="text"
                        onClick={getGroupStatus}
                        startIcon={<Iconify icon="eva:refresh-fill" />}
                    >
                        refresh
                    </Button>

                </Stack>
            </Stack>

            <Tabs selectionFollowsFocus
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Payments Management"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab sx={{ alignSelf: 'start' }} label="Active Subscribers" {...a11yProps(0)} />
                <Tab sx={{ alignSelf: 'start' }} label="Deleted Subscribers" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <SubscribersTable getSubscribers={getGroupStatus} setValue={setValue} subscribers={filter(data.subscribers, (_item) => !_item.is_deleted && _item.position !== "#0")} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SubscribersTable getSubscribers={getGroupStatus} setValue={setValue} subscribers={filter(data.subscribers, (_item) => _item.is_deleted)} />
            </TabPanel>
        </Card>
    );
}
