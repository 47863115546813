/* eslint-disable*/
import React, { useEffect, useState } from 'react'
import T from 'prop-types'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';


import { styled } from '@mui/material/styles';
import { Alert, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, IconButton, Snackbar, TextField } from '@mui/material'
import { postData } from '../../../utils/generalFunctions'
import { CONTRIBUTION_DATES } from '../../../utils/urls'
import { fDateDMY } from '../../../utils/formatTime'
import Iconify from '../../../components/Iconify'
import { LoadingButton } from '@mui/lab';





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Iconify
                        icon="eva:close-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: T.node,
    onClose: T.func.isRequired,
};

ContributionDays.propTypes = {
    group: T.object
}


export default function ContributionDays({ group }) {
    const [data, setDates] = useState([])
    const [open, setOpen] = useState(false)
    const [date, setDate] = useState(fDateDMY(new Date()))
    useEffect(() => {
        getContributionDays()
    }, [group])

    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'error',
        message: ''
    })

    const getContributionDays = async () => {
        await postData(`${CONTRIBUTION_DATES}?groupId=${group?.id}`, {}, 'GET').then((response) => {
            if (response.status) {
                setDates(response.data)
            }
        })
    }

    const [loading, setLoading] = useState(false)

    const createContributionDay = async () => {
        setLoading(true)
        await postData(`${CONTRIBUTION_DATES}`, { groupId: group.id, date }, 'POST').then((response) => {
            if (response.status) {
                toggleOpen()
                setDates(response.data)
                setState({ ...state, isOpen: true, severity: 'success', message: response.message });
            } else {
                setState({ ...state, isOpen: true, severity: 'error', message: response.message });
            }
        })
        setLoading(false)
    }

    const toggleOpen = (e) => {
        setOpen(!open);
    };

    const handleChange = (newValue) => {
        setDate(fDateDMY(newValue));
    };


    const { severity, horizontal, vertical, isOpen, message } = state;

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, message: '' });
    };

    return (
        <Grid container>
            <Snackbar key={vertical + horizontal} anchorOrigin={{
                vertical,
                horizontal,
            }} open={isOpen} autoHideDuration={5000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <BootstrapDialog
                onClose={toggleOpen}
                maxWidth='sm'
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <Snackbar key={vertical + horizontal} anchorOrigin={{
                    vertical,
                    horizontal,
                }} open={isOpen} autoHideDuration={5000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={toggleOpen}>
                    Contribution Day
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label='Date'
                            value={date}
                            maxDate={new Date()}
                            onChange={handleChange}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={loading} onClick={createContributionDay} variant='contained' color='info'>Submit Date</LoadingButton>
                </DialogActions>
            </BootstrapDialog>



            <Grid item sm={12} lg={12}>
                <Button sx={{ mb: 4, float: 'right', color: 'white' }} size='small' color='warning' onClick={toggleOpen} variant='contained'>+ Contribution Day</Button>
            </Grid>

            {data?.map(({ date }, index) => (
                <Grid item sm={6} key={index} lg={1} xl={1}>
                    <Chip label={date} key={index} sx={{ m: 1, cursor: 'grab' }} color={index === 0 ? 'info' : index === data.length - 1 ? 'error' : 'secondary'} variant={index === 0 ? 'filled' : 'outlined'} />
                </Grid>
            ))}
        </Grid>
    )
}
