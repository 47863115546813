import { useState } from "react";
import * as React from 'react';

import PropTypes from "prop-types";
import { filter } from "lodash";
import {
    Table, TableRow, TableBody,
    Snackbar, Alert, TableCell, TableContainer, TablePagination, Typography, Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, Stack, Badge, Backdrop, TextField, Box
} from '@mui/material';
import ConfirmDialog from "../../../../components/ConfirmDialog";
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { TableListHead, TableListToolbar } from '../../table';
import Iconify from "../../../../components/Iconify";
import { postData } from "../../../../utils/generalFunctions";
import { APPROVE_LOANS } from "../../../../utils/urls";

const TABLE_HEAD = [
    { id: 'member_name', label: 'Name', alignRight: false },
    { id: 'requested_amount', label: 'Amount Requested', alignRight: false },
    { id: 'loan_rate', label: 'Loan Rate', alignRight: false },
    { id: 'mobile', label: 'Tel No.', alignRight: false },
    { id: 'createdOn', label: 'Requested On', alignRight: false },
    { id: 'updatedOn', label: 'Updated On', alignRight: false },
    { id: '', label: '', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


DormantLoansView.propTypes = {
    loans: PropTypes.array,
    setValue: PropTypes.func,
    getLoans: PropTypes.func,
}

DormantLoansView.defaultProps = {
    loans: []
}

export default function DormantLoansView({ loans, setValue, getLoans }) {
    const [state, setState] = useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'error',
        message: '',
        isSubmitting: false,
        request: {},
        loan_id: '',
        approval_status: '',
        amount_approved: '',
        payment_frequency: '',
        total_number_of_weeks: '',
        member_name: ''
    });

    const [messageToast, setMessageToast] = React.useState('')
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [confirmMessage, setConfirmMessage] = React.useState('')
    const [open, setOpen] = React.useState(false)

    const { amount_approved, payment_frequency, total_number_of_weeks, severity, member_name, horizontal, vertical, isSubmitting, isOpen, message } = state;
    const filteredManagers = applySortFilter(loans, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredManagers.length === 0;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - loans.length) : 0;


    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_request) => `${_request.id}`.indexOf(query) !== -1
                || `${_request.mobile}`.indexOf(query) !== -1
                || `${_request.member_name}`.toLowerCase().indexOf(query) !== -1
                || `${_request.requested_amount}`.indexOf(query) !== -1
                || `${_request.loan_account_number.toLowerCase()}`.indexOf(query) !== -1
                || `${_request.region.toLowerCase()}`.indexOf(query) !== -1
                || `${_request.national_number}`.indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = loans.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'info' });
    };



    const toggleOpen = async (item = null) => {
        if (open) {
            setOpen(!open)
        } else {
            const { id, mobile, member_name, requested_amount, loan_rate } = item;

            setState({ ...state, loan_id: id, amount_approved: requested_amount, member_name })

            const message = `Confirm to approve loan of KES${requested_amount} for ${member_name} at a rate of ${loan_rate}%.`
            setConfirmMessage(message)
            setOpen(!open)
        }
    }

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const approvalUI = (
        <Box sx={{ mt: 2 }}>
              <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={2000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                        {messageToast}
                    </Alert>
                </Snackbar>
            <Stack sx={{ mb: 2 }} spacing={2} >
                <TextField
                    fullWidth
                    value={member_name}
                    disabled
                    autoComplete="member_name"
                    type="text"
                    label="Member name"
                />

                <TextField
                    fullWidth
                    value={amount_approved}
                    disabled={isSubmitting}
                    autoComplete="amount_approved"
                    onChange={handleChange}
                    name="amount_approved"
                    type="text"
                    label="Amount approved"
                />

                <TextField
                    fullWidth
                    value={payment_frequency}
                    disabled={isSubmitting}
                    autoComplete="payment_frequency"
                    name='payment_frequency'
                    onChange={handleChange}
                    type="text"
                    label="Number of Installments"
                />

                <TextField
                    fullWidth
                    value={total_number_of_weeks}
                    disabled={isSubmitting}
                    autoComplete="total_number_of_weeks"
                    name='total_number_of_weeks'
                    onChange={handleChange}
                    type="text"
                    label="Number of repayment weeks"
                />
            </Stack>

        </Box>
    )


    const onApproval = async (status) => {
        state.approval_status = status
        setState({ ...state, isSubmitting: true })
        await postData(APPROVE_LOANS, state, 'PUT')
            .then(response => {
                setMessageToast(response.message)
                setState({ ...state, isSubmitting: false })
                if (response.status) {
                    getLoans()
                    if(status===1){
                        setValue(1)
                    }else{
                        setValue(2)
                    }
                    setState({ ...state, isOpen: true, severity: 'success' });
                    toggleOpen()
                } else {
                    setState({ ...state, isOpen: true, severity: 'error' });
                }
            });
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            // onClick={handleClose}
            >
                <ConfirmDialog maxWidth="md" title={<Typography variant='body1'> {confirmMessage}</Typography>} open={open} acceptText='Approve' dismissText="Leave it!" cancelText='Decline!' isSubmitting={isSubmitting}
                    message={approvalUI}
                    onConfirm={() => onApproval(1)} onDismiss={toggleOpen} onDecline={() => onApproval(2)} />

            </Backdrop>

            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                childComponent={<Badge badgeContent={filteredManagers.length} showZero color="primary"><Iconify icon='flat-color-icons:debt' /></Badge>}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={loans.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                        />
                        <TableBody>
                            {filteredManagers && filteredManagers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id, mobile, status, member_name, requested_amount, loan_rate, createdOn, updatedOn } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell align="left">{member_name}</TableCell>
                                            <TableCell align="left">{requested_amount}</TableCell>
                                            <TableCell align="left">{loan_rate}</TableCell>
                                            <TableCell align="left">{mobile}</TableCell>
                                            <TableCell align="left">{createdOn}</TableCell>
                                            <TableCell align="left">{updatedOn}</TableCell>
                                            <TableCell align="right">
                                                <Stack direction='row'>
                                                    {status === 0 ? <Button onClick={() => toggleOpen(row)} variant='text' color='info' size="small">process</Button> : <Button color='info' variant='text' size="small">No Action</Button>}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={loans.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}