/* eslint-disable-line no-unused-vars */

import { Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import DeletedAccounts from './DeletedAccounts';
// components
import GroupCurrentBalance from './GroupCurrentBalance';
import GroupMembers from './GroupMembers';
import GroupTransfersGraph from './GroupTransfersGraph';
import IncomeGenerated from './IncomeGenerated';
import PenaltiesPaid from './PenaltiesPaid';
import PendingPayments from './PendingPayments';
import useResponsive from '../../../../hooks/useResponsive';
// ----------------------------------------------------------------------
GroupCards.propTypes = {
    current_balance: PropTypes.number,
    members: PropTypes.number,
    service_charge_balance: PropTypes.number,
    penalties_balance: PropTypes.number,
    tuliza_payments: PropTypes.number,
    deleted_accounts: PropTypes.number,
}

GroupCards.defaultProps = {
    current_balance: 0,
    members: 0,
    service_charge_balance: 0,
    penalties_balance: 0,
    tuliza_payments: 0,
    deleted_accounts: 0,
}
export default function GroupCards({ current_balance, members, service_charge_balance, penalties_balance, tuliza_payments, deleted_accounts }) {
    const isDesktop = useResponsive('up', 'md');
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
                <GroupCurrentBalance value={current_balance} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <GroupMembers value={members} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <IncomeGenerated value={service_charge_balance} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <PenaltiesPaid value={penalties_balance} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Stack spacing={2}>
                    <PendingPayments value={tuliza_payments} />
                    <DeletedAccounts value={deleted_accounts} />
                </Stack>
            </Grid>
            <Grid hidden={!isDesktop} item xs={12} md={9} lg={9}>
                <GroupTransfersGraph />
            </Grid>
        </Grid>
    );
}
