/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */

import { filter } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// material
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer, TablePagination
} from '@mui/material';
import { sentenceCase } from 'change-case';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import Label from '../../../components/Label';
import { TableListHead, TableListToolbar, SubscriptionMenuOptions } from '../table';
import { fNumber, fPercent } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Membership ID', alignRight: false },
    { id: 'group_name', label: 'Chama', alignRight: false },
    { id: 'account_type', label: 'Chama Type', alignRight: false },
    { id: 'contribution_account', label: 'A/C', alignRight: false },
    { id: 'position', label: 'Slot#', alignRight: false },
    { id: 'contribution_amount', label: 'Amount', alignRight: false },
    { id: 'contribution_date', label: 'Due on', alignRight: false },
    { id: 'frequency', label: 'Frequency', alignRight: false },
    // { id: 'role', label: 'Role', alignRight: false },
    { id: 'has_contributed', label: 'Contributed?', alignRight: false },
    { id: 'has_received', label: 'Received?', alignRight: false },
    { id: 'penalty', label: 'Penalty Charge', alignRight: false },
    // { id: 'fine_charge', label: 'Penalty Charge', alignRight: false },
    // { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_subscription) => _subscription.group_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
            || `${_subscription.id}`.indexOf(query) !== -1
            || `${_subscription.contribution_amount}`.indexOf(query) !== -1
            || _subscription.contribution_account.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    return stabilizedThis.map((el) => el[0]);
}
MemberShipChamas.propTypes = {
    subscriptions: PropTypes.array
}
MemberShipChamas.defaultProps = {
    subscriptions: [],
}
export default function MemberShipChamas({ subscriptions }) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = subscriptions.map((n) => n.id);

            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subscriptions.length) : 0;

    const filteredSubscriptions = applySortFilter(subscriptions, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredSubscriptions.length === 0;

    return (
        <Card>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mr={5} ml={5} mt={5} mb={5}>
                <Typography variant="h5" gutterBottom>
                    Membership Chamas
                </Typography>
                <Button
                    variant="contained"
                    component={RouterLink}
                    to="/payments/chama-payments"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                >
                    Contribute
                </Button>

            </Stack>
            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={subscriptions.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                        />
                        <TableBody>
                            {filteredSubscriptions && filteredSubscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id, group_name, charge_on_percent, charge_fines, account_type, contribution_account, position, contribution_amount, contribution_date,
                                        frequency, has_contributed, has_received, fine_percent, fine_charge, start_hour } = row;

                                    const isItemSelected = selected.indexOf(id) !== -1;
                                    const suffix = start_hour >= 12 ? "pm" : "am";
                                    const hour = ((21 + 11) % 12 + 1) + suffix
                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell align="left">{id}</TableCell>
                                            <TableCell align="left">{group_name}</TableCell>
                                            <TableCell align="left">
                                                <Label
                                                    variant="ghost"
                                                    color={(account_type === 'MGR' && 'error') || 'success'}
                                                >
                                                    {sentenceCase(account_type)}
                                                </Label>
                                            </TableCell>
                                            <TableCell align="left">{contribution_account}</TableCell>
                                            <TableCell align="left">{position}</TableCell>
                                            <TableCell align="left">{contribution_amount}</TableCell>
                                            <TableCell align="left">{contribution_date}</TableCell>
                                            <TableCell align="left">{frequency}</TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={has_contributed}
                                                />
                                            </TableCell>

                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={has_received}
                                                />
                                            </TableCell>
                                            <TableCell padding="normal">
                                                <Stack direction='row'>
                                                    <Checkbox
                                                        checked={charge_fines}
                                                    /> {charge_fines ? <Typography variant='body2' sx={{ pt: 1 }}>{`KES${charge_on_percent ? (contribution_amount * fine_percent / 100) : fNumber(fine_charge)} from ${hour}`}</Typography> : ''}
                                                </Stack>
                                            </TableCell>
                                            {/* <TableCell align="right">
                                                <SubscriptionMenuOptions />
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>
            <Stack direction='row' alignItems="center" justifyContent="space-between" mr={5} ml={5} mt={5} mb={5}>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
                    component="div"
                    count={subscriptions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Stack>
        </Card>
    );
}
