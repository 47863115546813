/* eslint-disable array-callback-return */
/* eslint-disable no-extend-native */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    Alert,
    Snackbar, DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    Typography,
    FormControlLabel,
    Checkbox
} from '@mui/material';


import { LoadingButton } from '@mui/lab';
import { ADMIN_TO_GROUP, SEARCH_CLIENT } from '../../../../utils/urls';
import Iconify from '../../../../components/Iconify';
import { postData } from '../../../../utils/generalFunctions';
import ConfirmDialog from '../../../../components/ConfirmDialog';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



const schema = Yup.object().shape({
    first_name: Yup.string().required('Please populate the mobile number field then click search.'),
    last_name: Yup.string().required('This field is required as part of the KYC process.'),
    mobile: Yup.string().required('This field is required as part of the KYC process.'),
    national_number: Yup.string().required('This field is required as part of the KYC process.'),
    region: Yup.string().notRequired('This field is part of the KYC process.'),
    is_signatory: Yup.bool().notRequired()
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Iconify
                        icon="eva:close-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

CreateManager.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    getManagers: PropTypes.func
};


export default function CreateManager(props) {
    const data = useSelector(state => state.selectedGroup)
    const { open, setOpen, getManagers } = props
    const [message, setMessage] = React.useState('')

    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'left',
        severity: 'error'
    });

    const searchMember = async () => {
        await postData(`${SEARCH_CLIENT}`, values, 'POST').then((response) => {
            try {
                if (response.status) {
                    getManagers()
                    setMessage(response.message)
                    setState({ ...state, isOpen: true, severity: 'success' });
                    formik.setValues({ ...response.user, mobile: values.mobile, position: values.position, account_number: data.next_member_account.account })
                } else {
                    setMessage(response.message)
                    setState({ ...state, isOpen: true, severity: 'error' });
                }
            } catch (error) {
                console.log(error);
            }
        }).catch((e)=>{
            setMessage(e.message)
            setState({ ...state, isOpen: true, severity: 'error' });
        })
    }



    const formik = useFormik({
        initialValues: {
            mobile: '',
            first_name: '',
            last_name: '',
            national_number: '',
            groupId: data.group_id,
            region: '',
            is_signatory: false
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values) => {
            values.groupId = data.group_id
            postData(ADMIN_TO_GROUP, values)
                .then(data => {
                    if (data.status) {
                        setMessage(data.message)
                        setState({ ...state, isOpen: true, severity: 'success' });
                        formik.resetForm()
                        formik.setSubmitting(false)
                        formik.isValidating = false
                        setTimeout(() => {
                            setOpen(false)
                        }, 2000)
                    } else {
                        formik.setSubmitting(false)
                        formik.isValidating = false
                        setMessage(data.message)
                        setState({ ...state, isOpen: true, severity: 'error' });
                    }
                }).catch((error) => {
                    formik.isValidating = false
                    formik.setSubmitting(false)
                    setMessage(error.message)
                    setState({ ...state, open: true, severity: 'error' });
                });
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const { severity, horizontal, vertical, isOpen } = state;

    const handleSnackClose = () => {
        setMessage('')
        setState({ ...state, isOpen: false });
    };

    const handleClose = () => {
        setOpen();
    };


    const [confirmMessage, setConfirmMessage] = React.useState('')
    const [openConfirm, setOpenConfirm] = React.useState('')


    const handleOnDecline = () => {
        setOpenConfirm(false)
    }

    const handleOnConfirm = () => {
        setOpenConfirm(false)
        formik.submitForm()
        setConfirmMessage('')
    }



    return (
        <div>
            <ConfirmDialog isSubmitting={formik.isSubmitting} showDismiss={false} title='Submit member details?' open={openConfirm} message={confirmMessage} onConfirm={handleOnConfirm} onDecline={handleOnDecline} />
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={10000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth='md'
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Create Managers <Typography variant='caption'>(a group manager must be registered on our system)</Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Alert style={{ display: message === "" ? 'none' : undefined }} severity={severity} sx={{ marginBottom: 2 }}>{message}</Alert>
                    <FormikProvider value={formik}>
                        <Form disabled={isSubmitting} autoComplete="on" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    disabled={isSubmitting}
                                    autoComplete="mobile"
                                    type="text"
                                    label='Telephone number'
                                    {...getFieldProps('mobile')}
                                    error={Boolean(touched.mobile && errors.mobile)}
                                    helperText={touched.mobile && errors.mobile}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LoadingButton
                                                    disabled={values.mobile.length < 10}
                                                    autoFocus
                                                    size="small"
                                                    // type="submit"
                                                    onClick={searchMember}
                                                    color='secondary'
                                                    variant="contained"
                                                >
                                                    <Iconify
                                                        icon="eva:search-fill"
                                                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                                    />   search
                                                </LoadingButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Stack direction='row' alignItems="center" spacing={2} justifyContent="space-between">
                                    <TextField
                                        fullWidth
                                        disabled
                                        autoComplete="first_name"
                                        type="text"
                                        label="First Name"
                                        {...getFieldProps('first_name')}
                                        error={Boolean(touched.first_name && errors.first_name)}
                                        helperText={touched.first_name && errors.first_name}
                                    />

                                    <TextField
                                        fullWidth
                                        disabled
                                        autoComplete="last_name"
                                        type="text"
                                        label="Last Name"
                                        {...getFieldProps('last_name')}
                                        error={Boolean(touched.last_name && errors.last_name)}
                                        helperText={touched.last_name && errors.last_name}
                                    />
                                </Stack>

                                <TextField
                                    fullWidth
                                    disabled
                                    autoComplete="national_number"
                                    type="text"
                                    label='Manager ID number'
                                    {...getFieldProps('national_number')}
                                    error={Boolean(touched.national_number && errors.national_number)}
                                    helperText={touched.national_number && errors.national_number}
                                />

                                <TextField
                                    fullWidth
                                    disabled
                                    autoComplete="region"
                                    type="text"
                                    label='Region'
                                    {...getFieldProps('region')}
                                    error={Boolean(touched.region && errors.region)}
                                    helperText={touched.region && errors.region}
                                />

                                <FormControlLabel
                                    control={<Checkbox {...getFieldProps('is_signatory')} />}
                                    label={<Typography variant='button'>Make a signatory? <Typography variant='caption'> (Payments will require their approval to be disbursed)</Typography></Typography>}
                                />
                            </Stack>


                            <DialogActions>
                                <LoadingButton
                                    autoFocus
                                    size="small"
                                    variant="outlined"
                                    loading={isSubmitting}
                                    onClick={() => formik.resetForm()}
                                >
                                    Clear
                                </LoadingButton>

                                <LoadingButton
                                    sx={{ display: values.national_number !== '' ? '' : 'none' }}
                                    autoFocus
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Enroll
                                </LoadingButton>
                            </DialogActions>
                        </Form>
                    </FormikProvider>
                </DialogContent>

            </BootstrapDialog>
        </div>
    );
}
