import {
    Typography,
    Box,
    Card,
} from '@mui/material';
import TransactionsTable from '../sections/@dashboard/management/@tables/Transactions';
import Page from '../components/Page';
import { getStorageItem } from '../utils/generalFunctions';

export default function Statements() {
    return (
        <Page title='Transactions'>
            <Card>
                <Box sx={{ p: 2 }}>
                    <Typography sx={{ p: 2 }} variant="body1">A/C Transactions</Typography>
                    <TransactionsTable savings_id={getStorageItem('savings_id')} />
                </Box>

            </Card>
        </Page>
    );
}






